/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";

import bigChartData from "variables/charts.js";
import Examples from "./Examples";
import IndexNavbar from "components/Navbars/IndexNavbar";

export default function PrivacyPolicy() {

  return (
    <>
    <IndexNavbar />
    <div className="wrapper">        
    
    <div className="section section-basic" id="basic-elements">
      <Container>
      <div>
	  <strong>
	  <span>
<span data-custom-class="title">
	  <div className="block-component"></div>
	  <div className="question">PRIVACY POLICY</div>
	  <div className="statement-end-if-in-editor"></div>
	  </span>
	  </span>
	  </strong>
	  </div>
	  <div><br></br></div>
	  <div><span>
	  <strong><span>
	  <span data-custom-class="subtitle">Last updated April 02, 2023</span>
	  </span>
	  </strong>
	  </span>
	  </div>
	  <div><br></br></div>
	  <div><br></br></div>
	  <div><br></br></div>
	  <div>
<span>
<span>
<span data-custom-class="body_text">This privacy notice for Daydream Starter Inc. (doing business as Daydream Inc.) ("<strong>Daydream Inc.</strong>," "<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>"
</span>
<span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when you:
</span></span>
</span>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div>
</span></span>
</span></span>
</span><div><div className="block-component">
<span>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div>
</span></span>
</span></span>
</span></span></div></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">Download and use our mobile application (Wurus), or any other application of ours that links to this privacy notice
</span>
</span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div>
</span></span>
</span></span>
</span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or events
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<div className="statement-end-if-in-editor"></div>
</span></span>
</span></span>
</span></span>
</span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at contact@daydream.company
</span>
</span>
</span>
</div>
<div>
<br></br></div>
<div>
<br></br></div>
<div>
  <strong>
<span>
<span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
</span></strong></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our&nbsp;</em></strong>
</span></span><a data-custom-class="link" href="#toc">
<span>
<span data-custom-class="body_text"><strong><em>table of contents</em></strong>
</span></span></a>
<span>
<span data-custom-class="body_text"><strong><em>&nbsp;below to find the section you are looking for.</em></strong>
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with <div className="block-component"></div>Daydream Inc.<div className="else-block"></div> and the Services, the choices you make, and the products and features you use. Learn more about&nbsp;
</span></span><a data-custom-class="link" href="#personalinfo">
<span>
<span data-custom-class="body_text">personal information you disclose to us</span></span></a>
<span data-custom-class="body_text"
>.</span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>Do we process any sensitive personal information?</strong> <div className="block-component"></div>We do not process sensitive personal information.<div className="else-block"></div>
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>Do we receive any information from third parties?</strong> <div className="block-component"></div>We do not receive any information from third parties.<div className="else-block"></div>
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about&nbsp;
</span></span><a data-custom-class="link" href="#infouse">
<span>
<span data-custom-class="body_text">how we process your information</span></span></a>
<span data-custom-class="body_text"
>.</span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>In what situations and with which <div className="block-component"></div>parties do we share personal information?</strong> We may share information in specific situations and with specific <div className="block-component"></div>third parties. Learn more about&nbsp;
</span></span><a data-custom-class="link" href="#whoshare">
<span>
<span data-custom-class="body_text">when and with whom we share your personal information</span></span></a>
<span>
<span data-custom-class="body_text">.<div className="block-component"></div>
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>How do we keep your information safe?</strong> We have <div className="block-component"></div>organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <div className="block-component"></div>unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about&nbsp;
</span></span><a data-custom-class="link" href="#infosafe">
<span>
<span data-custom-class="body_text">how we keep your information safe</span></span></a>
<span data-custom-class="body_text">.</span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div>
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about&nbsp;
</span></span><a data-custom-class="link" href="#privacyrights">
<span>
<span data-custom-class="body_text">your privacy rights</span></span></a>
<span data-custom-class="body_text"
>.</span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by <div className="block-component"></div>visiting contact@daydream.company<div className="else-block"></div>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text">Want to learn more about what <div className="block-component"></div>Daydream Inc.<div className="else-block"></div> does with any information we collect?&nbsp;
</span></span><a data-custom-class="link" href="#toc">
<span>
<span data-custom-class="body_text">Review the privacy notice in full</span></span></a>
<span>
<span data-custom-class="body_text"
>.</span></span></div>
<div>
<br></br></div>
<div>
<br></br></div>
<div id="toc">
<span>
<span>
<span>
<strong>
<span data-custom-class="heading_1">TABLE OF CONTENTS</span></strong>
</span></span>
</span></div>
<div>
<br></br></div>
<div>
<span>
<a data-custom-class="link" href="#infocollect">
<span>1. WHAT INFORMATION DO WE COLLECT?
</span></a>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#infouse">
<span>2. HOW DO WE PROCESS YOUR INFORMATION?<div className="block-component"></div>
</span></a>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#legalbases">
<span>3. 
<span>
<span>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
</span></span><div className="statement-end-if-in-editor"></div>
</span></a>
</span></div>
<div>
<span>
<span>
<a data-custom-class="link" href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
</span>
<span data-custom-class="body_text"><div className="block-component"></div>
</span>
<span>
<span>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div>
</span></span>
<span data-custom-class="body_text">
<span>
<span>
<span>
<div className="block-component"></div>
</span></span><div className="block-component"></div>
</span></span>
</span></span>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#intltransfers">
<span>5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
</span></a>
<span>
<span>
<span data-custom-class="body_text">
<span>
<div className="statement-end-if-in-editor"></div>
</span></span>
</span></span>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#inforetain">
<span>6. HOW LONG DO WE KEEP YOUR INFORMATION?
</span></a>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<div className="block-component"></div>
</span></span>
</span></span>
</span></span></div>
<div>
<span>
<a data-custom-class="link" href="#infosafe">
<span>7. HOW DO WE KEEP YOUR INFORMATION SAFE?
</span></a>
<span>
<span>
<span data-custom-class="body_text">
<span>
<div className="statement-end-if-in-editor"></div><div className="block-component"></div>
</span></span>
</span></span>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#infominors">
<span>8. DO WE COLLECT INFORMATION FROM MINORS?
</span></a>
<span>
<span>
<span data-custom-class="body_text">
<span>
<div className="statement-end-if-in-editor"></div>
</span></span>
</span></span>
</span></div>
<div>
<span>
<span>
<a data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
</span></span></div>
<div>
<span>
<a data-custom-class="link" href="#DNT">
<span>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#caresidents">
<span>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
</span></a>
</span><div className="block-component">
<span>
<span data-custom-class="body_text"
></span></span></div></div>
<div>
<a data-custom-class="link" href="#virginia">
<span>12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
</span></a><span>
<div className="statement-end-if-in-editor">
<span data-custom-class="body_text"
></span></div>
</span></div>
<div>
<span>
<a data-custom-class="link" href="#policyupdates">
<span>13. DO WE MAKE UPDATES TO THIS NOTICE?
</span></a>
</span></div>
<div>
<a data-custom-class="link" href="#contact">
<span>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
</span></a></div>
<div>
<a data-custom-class="link" href="#request">
<span>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
</span></a></div>
<div>
<br></br></div>
<div>
<br></br></div>
<div id="infocollect">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?
</span></strong>
</span></span>
</span></span>
</span></div>
<div>
<br></br></div>
<div id="personalinfo">
<span data-custom-class="heading_2">
<span>
<strong>Personal information you disclose to us</strong>
</span></span></div>
<div><div><br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:</em></strong>
</span></span>
</span></span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect personal information that you provide to us.</em>
</span></span>
</span></span>
</span></span></div>
</div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span>
<div className="block-component"></div>
</span></span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
</span></span>
</span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div>
</span></span>
</span></span></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:<span>
<span data-custom-class="body_text"><div className="forloop-component"></div>
</span></span>
</span></span>
</span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">names
</span></span>
</span></span>
</span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div>
</span></span>
</span></span>
</span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">phone numbers
</span></span>
</span></span>
</span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div>
</span></span>
</span></span>
</span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">mailing addresses
</span></span>
</span></span>
</span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div>
</span></span>
</span></span>
</span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">passwords</span></span></span></span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div></span><span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></div>
<div id="sensitiveinfo">
<span>
<span data-custom-class="body_text"><strong>Sensitive Information.</strong> <div className="block-component"></div>We do not process sensitive information.</span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text"><div className="else-block"></div></span></span><span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by<div className="forloop-component"></div><span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span> PayTech<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div></span></span></span></span></span></span></span></span></span></span></span></span>. You may find their privacy notice link(s) here:<span>
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div><span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span> <a href="https://paytech.sn/" target="_blank" data-custom-class="link">https://paytech.sn/</a><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span><div className="forloop-component"></div><span>
<span data-custom-class="body_text">.<div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"><div className="block-component"></div></div></span></span></span></span><div className="block-component"><div className="block-component"></div></div></span></div>
<div>
<span data-custom-class="body_text">
<span>
<strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:<div className="block-component"></div></span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<em>Geolocation Information.</em> We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</span></span></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span>
<span data-custom-class="body_text"><em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.<div className="statement-end-if-in-editor"></div></span></span></li></ul><div>
<span>
<span data-custom-class="body_text">This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</span></span></div>
<div>
<br></br></div>
<div>
<div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<div>
<span>
<span>
<span data-custom-class="body_text">All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span><span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"><div className="block-component"></div></div></span></span></span></span></span></span></span><span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></div>
<div id="infouse">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
<div><div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></span></span></span></span></span></span></div>
</div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong><div className="block-component"></div></span></span></span><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may process your information to provide you with the requested service.<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></div>
<ul><li>
<span>
<span>
<span data-custom-class="body_text"><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</span></span></span></li></ul><div>
<div className="block-component">
<span>
</span></div><div>
<div className="block-component">
<span>
</span></div><div>
<div className="block-component">
<span>
</span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<strong>To <div className="block-component"></div>fulfill and manage your orders.</strong> We may process your information to <div className="block-component"></div>fulfill and manage your orders, payments, returns, and exchanges made through the Services.</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><p>
<div className="block-component">
<span>
</span></div></p><p>
<div className="block-component">
<span>
</span></div></p><p>
<div className="block-component"></div></p><p>
<div className="block-component"></div></p><div>
<div className="block-component">
<span>
</span></div><div>
<div className="block-component">
<span>
</span></div><div>
<div className="block-component">
<span>
</span></div><div>
<span>
<div className="block-component">
<span data-custom-class="body_text"></span></div></span><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<div>
<br></br></div>
<div id="legalbases">
<strong><span>
<span data-custom-class="heading_1">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</span></span></strong></div>
<div>
<br></br></div>
<div>
<em><span>
<span data-custom-class="body_text"><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.<div className="block-component"></div>, legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or <div className="block-component"></div>fulfill our contractual obligations, to protect your rights, or to <div className="block-component"></div>fulfill our legitimate business interests.</span></span></em><span>
<span data-custom-class="body_text"><div className="block-component"></div></span><span data-custom-class="body_text"><div className="block-component"></div></span></span></div>
<div>
<br></br></div>
<div>
<em><span>
<span data-custom-class="body_text"><strong><u>If you are located in the EU or UK, this section applies to you.</u></strong></span></span></em><span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text">The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</span></span></div>
<ul><li>
<span>
<span data-custom-class="body_text"><strong>Consent.&nbsp;</strong>We may process your information if you have given us permission (i.e.<div className="block-component"></div>, consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about&nbsp;</span></span><a data-custom-class="link" href="#withdrawconsent">
<span>
<span data-custom-class="body_text">withdrawing your consent</span></span></a><span data-custom-class="body_text">.</span></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<strong>Performance of a Contract.</strong> We may process your personal information when we believe it is necessary to <div className="block-component"></div>fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.<br></br></span></span></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>
<strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span></div>
<div>
<br></br></div>
<div>
<span data-custom-class="body_text">
<span>
<strong><u><em>If you are located in Canada, this section applies to you.</em></u></strong></span></span></div>
<div>
<br></br></div>
<div>
<span data-custom-class="body_text">
<span>We may process your information if you have given us specific permission (i.e.<div className="block-component"></div>, express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e.<div className="block-component"></div>, implied consent). You can&nbsp;</span></span><a data-custom-class="link" href="#withdrawconsent">
<span data-custom-class="body_text">
<span>withdraw your consent</span></span></a><span data-custom-class="body_text">
<span>&nbsp;at any time.</span></span></div>
<div>
<br></br></div>
<div>
<span data-custom-class="body_text">
<span>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</span></span></li></ul><div>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>For investigations and fraud detection and prevention</span></span></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>For business transactions provided certain conditions are met</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span data-custom-class="body_text">
<span>For identifying injured, ill, or deceased persons and communicating with next of kin</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></li></ul><div>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</span></span></li></ul><div>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</span></span></li></ul><div>
<span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span></div>
<ul><li>
<span data-custom-class="body_text">
<span>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</span></span></li></ul><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<ul><li>
<span>
<span data-custom-class="body_text">If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</span></span></li></ul><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></div>
<ul><li>
<span>
<span data-custom-class="body_text">If the collection is solely for journalistic, artistic, or literary purposes</span></span></li></ul><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></div>
<ul><li>
<span>
<span data-custom-class="body_text">If the information is publicly available and is specified by the regulations</span><div className="statement-end-if-in-editor">
<span data-custom-class="body_text"></span></div></span></li></ul><div>
<div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></div>
<div>
<br></br></div>
<div id="whoshare">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></strong></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We may share information in specific situations described in this section and/or with the following <div className="block-component"></div>third parties.</em></span></span></span></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span data-custom-class="body_text">We <div className="block-component"></div>may need to share your personal information in the following situations:</span></span></div>
<ul><li>
<span>
<span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span></li></ul><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span><div>
<span>
<div className="block-component">
<span data-custom-class="body_text"></span></div></span><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><span data-custom-class="body_text">
<span>
<div className="block-component"></div></span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div><div>
<div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><span>
<span>
<span>
<span>
<span>
<div className="block-component">
<span data-custom-class="heading_1"></span></div></span></span></span></span></span><span data-custom-class="body_text">
<span>
<span>
<span>
<span>
<span>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span><div className="block-component">
<span data-custom-class="body_text"><div className="block-component"></div></span></div></span></span></span></span></span></span></span></span></div>
<div>
<br></br></div>
<div id="intltransfers">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></strong></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We may transfer, store, and process your information in countries other than your own.</em></span></span></span></div>
<div>
<br></br></div>
<div data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">Our servers are located in<span>
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div><div className="block-component"></div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span> the <span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">United States</span></span></span></span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span><div className="block-component"></div></span></span></span></span></span></span><div className="forloop-component"></div><span>
<span>
<span data-custom-class="body_text">. If you are accessing our Services from outside</span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div><div className="block-component"></div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span> the <span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">United States</span></span></span></span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span><div className="block-component"></div></span></span></span></span></span></span><div className="forloop-component"></div><span data-custom-class="body_text">, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see <div className="block-component"></div>"</span><a data-custom-class="link" href="#whoshare">
<span>
<span>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</span></span></a><span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>" above), in</span></span>&nbsp;</span><span>
<span>
<span data-custom-class="body_text"><div className="forloop-component"></div></span></span></span><span>
<span>
<span data-custom-class="body_text">&nbsp;and other countries.</span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span><span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span>
<span>
<span>
<span>
<span>
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></span></div>
<div>
<br></br></div>
<div id="inforetain">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We keep your information for as long as necessary to <div className="block-component"></div>fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).<div className="block-component"></div></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or <div className="block-component"></div>anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span>
<div className="block-component"></div></span></span></span></span></div>
<div>
<br></br></div>
<div id="infosafe">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>We aim to protect your personal information through a system of <div className="block-component"></div>organizational and technical security measures.</em></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">We have implemented appropriate and reasonable technical and <div className="block-component"></div>organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other <div className="block-component"></div>unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.<span>
<div className="statement-end-if-in-editor"></div></span><span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div>
<div>
<br></br></div>
<div id="infominors">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></span></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;We do not knowingly collect data from or market to <div className="block-component"></div>children under 18 years of age<div className="else-block"></div>.</em><div className="block-component"></div></span></span></span></div>
<div>
<br></br></div>
<div>
<span>
<span>
<span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span>
<span data-custom-class="body_text"><div className="block-component"></div>contact@daydream.company<div className="else-block"></div></span></span>.</span><span data-custom-class="body_text"><div className="else-block"></div></span></span></span></div>
<div>
<br></br></div><div id="privacyrights">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>&nbsp;<span>
<span>
<span data-custom-class="body_text"><em><div className="block-component"></div></em></span></span></span>In some regions, such as <div className="block-component"></div>the European Economic Area (EEA), United Kingdom (UK), and Canada<div className="block-component"></div>, you have rights that allow you greater access to and control over your personal information.<span>
<span>
<span data-custom-class="body_text"><em><div className="statement-end-if-in-editor"></div></em></span></span>&nbsp;</span>You may review, change, or terminate your account at any time.</em><span>
<span>
<div className="block-component"></div></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">In some regions (like <div className="block-component"></div>the EEA, UK, and Canada<div className="block-component"></div>), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section <div className="block-component"></div>"</span></span></span><a data-custom-class="link" href="#contact">
<span>
<span>
<span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a><span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>" below.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We will consider and act upon any request in accordance with applicable data protection laws.</span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></div><div>
<span>&nbsp;</span></div><div>
<span>
<span>
<span data-custom-class="body_text">If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><a data-custom-class="link" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" rel="noopener noreferrer" target="_blank">
<span>Member State data protection authority</span></a></span></span></span></span></span> or&nbsp;</span></span></span><a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" rel="noopener noreferrer" target="_blank">
<span data-custom-class="link">
<span>
<span>
<span data-custom-class="body_text">UK data protection authority</span></span></span></span></a><span>
<span>
<span data-custom-class="body_text">.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">If you are located in Switzerland, you may contact the <span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<a data-custom-class="link" href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="noopener noreferrer" target="_blank">Federal Data Protection and Information Commissioner</a></span></span></span></span></span></span>.</span></span></span></div><div>
<br></br></div><div id="withdrawconsent">
<span>
<span>
<span data-custom-class="body_text"><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information,<div className="block-component"></div> which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section <div className="block-component"></div>"</span></span></span><a data-custom-class="link" href="#contact">
<span>
<span>
<span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></span></span></a><span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>" below<div className="block-component"></div>.</span></span></span></div><div>
<br></br></div><div>
<span>
<span data-custom-class="body_text">However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,<div className="block-component"></div> when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<div className="block-component"></div></span></span><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<div className="block-component"></div></span></span></span></span></span></span></span></span></span></span><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div></div><div>
<br></br></div><div>
<span data-custom-class="body_text">
<span>If you have questions or comments about your privacy rights, you may email us at contact@daydream.company.</span></span><div className="statement-end-if-in-editor">
<span>
<span data-custom-class="body_text"></span></span></div></div><div>
<br></br></div><div id="DNT">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (<div className="block-component"></div>"DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for <div className="block-component"></div>recognizing and implementing DNT signals has been <div className="block-component"></div>finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></span></span></div><div>
<br></br></div><div id="caresidents">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><strong><em>In Short:&nbsp;</em></strong><em>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the <div className="block-component"></div>"Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g.<div className="block-component"></div>, backups, etc.).<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div><div>
<br></br></div><div>
<span data-custom-class="heading_2">
<span>
<strong>CCPA Privacy Notice</strong></span></span></div><div><div><br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">The California Code of Regulations defines a <div className="block-component"></div>"resident" as:</span></span></span></span></span></span></div></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">(1) every individual who is in the State of California for other than a temporary or transitory purpose and</span></span></span></div><div>
<span>
<span>
<span data-custom-class="body_text">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">All other individuals are defined as <div className="block-component"></div>"non-residents."</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">If this definition of <div className="block-component"></div>"resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><strong>What categories of personal information do we collect?</strong></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We have collected the following categories of personal information in the past twelve (12) months:<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div><div>
<br></br></div><table>
<tbody><tr><td>
<span>
<span>
<span data-custom-class="body_text"><strong>Category</strong></span></span></span></td><td>
<span>
<span>
<span data-custom-class="body_text"><strong>Examples</strong></span></span></span></td><td>
<span>
<span>
<span data-custom-class="body_text"><strong>Collected</strong></span></span></span></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">A. Identifiers</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">B. Personal information categories listed in the California Customer Records statute</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Name, contact information, education, employment, employment history, and financial information</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">C. Protected classification characteristics under California or federal law</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Gender and date of birth</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">D. Commercial information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Transaction information, purchase history, financial details, and payment information</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">E. Biometric information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Fingerprints and voiceprints</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">F. Internet or other similar network activity</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Browsing history, search history, online <div className="block-component"></div>behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">G. Geolocation data</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Device location</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">H. Audio, electronic, visual, thermal, olfactory, or similar information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Images and audio, video or call recordings created in connection with our business activities</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">I. Professional or employment-related information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">J. Education Information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Student records and directory information</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>NO</span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<div>
<span>
<span>
<span data-custom-class="body_text">K. Inferences drawn from other personal information</span></span></span></div></td><td>
<div>
<span>
<span>
<span data-custom-class="body_text">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</span></span></span></div></td><td>
<div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>YES<div className="else-block"></div></span></span></span></div><div>
<br></br></div></td></tr><tr><td>
<span data-custom-class="body_text">L. Sensitive Personal Information</span></td><td>
<div className="block-component">
<span data-custom-class="body_text"></span></div></td><td data-custom-class="body_text">
<br></br><div className="block-component">
<span data-custom-class="body_text"></span></div>NO<div className="statement-end-if-in-editor">
<span data-custom-class="body_text"></span></div><br></br><br></br></td></tr></tbody></table><div>
<div className="block-component"></div></div><div>
<br></br></div><div>
<div className="block-component">
<span data-custom-class="body_text"></span></div><span data-custom-class="body_text">We will use and retain the collected personal information as needed to provide the Services or for:<div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div>
<span data-custom-class="body_text"><div className="block-component"></div></span></div><ul><li>
<span data-custom-class="body_text">Category K - As long as the user has an account with us</span></li></ul><div>
<span data-custom-class="body_text"><div className="block-component"></div></span><div className="statement-end-if-in-editor">
<span data-custom-class="body_text"></span></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></div><div>
<span>
<span>
<span data-custom-class="body_text">We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">Receiving help through our customer support channels;</span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">Participation in customer surveys or contests; and</span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">Facilitation in the delivery of our Services and to respond to your inquiries.</span><span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text"><strong>How do we use and share your personal information?</strong></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">More information about our data collection and sharing practices can be found in this privacy notice<div className="block-component"></div>.</span></span></span></span><span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">You may contact us <span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span>by email at&nbsp;</span><span>
<span data-custom-class="body_text">contact@daydream.company, <div className="block-component"></div></span><span data-custom-class="body_text"><div className="block-component"></div><div className="block-component">
<span data-custom-class="body_text"></span></div></span></span></span></span><span data-custom-class="body_text">or by referring to the contact details at the bottom of this document.</span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">If you are using an <div className="block-component"></div>authorized agent to exercise your right to opt out we may deny a request if the <div className="block-component"></div>authorized agent does not submit proof that they have been validly <div className="block-component"></div>authorized to act on your behalf.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><strong>Will your information be shared with anyone else?</strong></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be <div className="block-component"></div>"selling" of your personal information.<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">Daydream Starter Inc.</span><span data-custom-class="body_text">&nbsp;has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. <span>
<span>
<span data-custom-class="body_text">Daydream Starter Inc.</span></span>&nbsp;</span>will not sell or share personal information in the future belonging to website visitors, users, and other consumers.<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span><div className="block-component"></div></span></span></span></span></span><span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><strong>Your rights with respect to your personal data</strong></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><u>Right to request deletion of the data — Request to delete</u></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><u>Right to be informed — Request to know</u></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">Depending on the circumstances, you have a right to know:<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">whether we collect and use your personal information;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">the categories of personal information that we collect;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">the purposes for which the collected personal information is used;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">whether we sell or share personal information to third parties;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">the categories of personal information that we sold, shared, or disclosed for a business purpose;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">the business or commercial purpose for collecting, selling, or sharing personal information; and<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></li></ul><div>
<div className="block-component">
<span data-custom-class="body_text"></span></div></div><ul><li>
<span data-custom-class="body_text">the specific pieces of personal information we collected about you.</span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We will not discriminate against you if you exercise your privacy rights.</span></span></span></div><div>
<br></br></div><div>
<u><span data-custom-class="body_text">Right to Limit Use and Disclosure of Sensitive Personal Information</span></u></div><div>
<div className="block-component">
<span data-custom-class="body_text"></span></div></div><div>
<br></br></div><div>
<span data-custom-class="body_text">We do not process consumer's sensitive personal information.</span></div><div>
<br></br></div><div>
<div className="statement-end-if-in-editor">
<span data-custom-class="body_text"></span></div></div><div>
<span>
<span>
<span data-custom-class="body_text"><u>Verification process</u></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g.<div className="block-component"></div>, phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><u>Other privacy rights</u></span></span></span></div><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">You may object to the processing of your personal information.<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">You can designate an <div className="block-component"></div>authorized agent to make a request under the CCPA on your behalf. We may deny a request from an <div className="block-component"></div>authorized agent that does not submit proof that they have been validly <div className="block-component"></div>authorized to act on your behalf in accordance with the CCPA.<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div><span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></span></span></span></span></span></span></div><ul><li>
<span>
<span>
<span data-custom-class="body_text">You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"></span></span></span></span></span></span></span></span></span></span></span></span></li></ul><div>
<span>
<span>
<span data-custom-class="body_text">To exercise these rights, you can contact us&nbsp;</span><span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div>by email at contact@daydream.company, <div className="block-component"></div></span><span data-custom-class="body_text"><div className="block-component"></div><div className="block-component">
<span data-custom-class="body_text"></span></div></span></span></span></span></span></span><span data-custom-class="body_text">or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</span><span>
<span data-custom-class="body_text"><div className="block-component"><div className="block-component">
<span>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"><div className="statement-end-if-in-editor"></div></div></span></span></span></span></span></span></span></span></span></span></div></div></span></span><div className="block-component">
<span>
</span></div></div><div>
<span>
<br></br></span></div><div>
<span id="virginia">
<strong><span data-custom-class="heading_1">12. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></div><div>
<span>
<br></br></span></div><div>
<span>
<em><strong><span data-custom-class="body_text">In Short:</span></strong><span data-custom-class="body_text">&nbsp;Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</span></em></span></div><div>
<span>
<br></br></span></div><div>
<span>
<strong><span data-custom-class="heading_2">Virginia CDPA Privacy Notice</span></strong></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text">Under the Virginia Consumer Data Protection Act (CDPA):</span></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</span></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>"Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. <div className="block-component"></div>"Personal data" does not include de-identified data or publicly available information.</span></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>"Sale of personal data" means the exchange of personal data for monetary consideration.</span></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text">If this definition <div className="block-component"></div>"consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data.</span></span></div><div>
<br></br></div><div>
<span>
<span data-custom-class="body_text">The information we collect, use, and disclose about you will vary depending on how you interact with Daydream Starter Inc. and our Services. To find out more, please visit the following links:</span><div className="block-component"></div></span></div><ul><li>
<span>
<a data-custom-class="link" href="#infocollect">Personal data we collect</a><div className="statement-end-if-in-editor"></div></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<a data-custom-class="link" href="#infouse">How we use your personal data</a></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<a data-custom-class="link" href="#whoshare">When and with whom we share your personal data</a></span></li></ul><div>
<span>
<u><span data-custom-class="body_text">Your rights with respect to your personal data</span></u></span></div><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to be informed whether or not we are processing your personal data</span></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to access your personal data</span></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to correct inaccuracies in your personal data</span></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to request deletion of your personal data</span></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to obtain a copy of the personal data you previously shared with us</span></span></li></ul><div>
<span>
<div className="block-component"></div></span></div><ul><li>
<span>
<span data-custom-class="body_text">Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects (<div className="block-component"></div>"profiling")</span></span><div className="statement-end-if-in-editor">
<span>
</span></div></li></ul><div data-custom-class="body_text">
<span data-custom-class="body_text">
<div className="block-component"></div>Daydream Starter Inc. has not sold any personal data to third parties for business or commercial purposes. Daydream Starter Inc. will not sell personal data in the future belonging to website visitors, users, and other consumers.<div className="else-block"></div></span></div><div>
<br></br></div><div>
<u><span data-custom-class="body_text">Exercise your rights provided under the Virginia CDPA</span></u></div><div>
<br></br></div><div>
<span data-custom-class="body_text">More information about our data collection and sharing practices can be found in this privacy notice<div className="block-component"></div>.</span></div><div>
<br></br></div><div>
<div className="block-component"></div><span>
<span data-custom-class="body_text">You may contact us by email at contact@daydream.company, <div className="block-component"></div>by visiting contact@daydream.company<div className="else-block"></div>, or by referring to the contact details at the bottom of this document.</span></span></div><div>
<br></br></div><div>
<span>
<span data-custom-class="body_text">If you are using an <div className="block-component"></div>authorized agent to exercise your rights, we may deny a request if the <div className="block-component"></div>authorized agent does not submit proof that they have been validly <div className="block-component"></div>authorized to act on your behalf.</span></span></div><div>
<br></br></div><div>
<u><span>
<span data-custom-class="body_text">Verification process</span></span></u></div><div>
<br></br></div><div>
<span>
<span data-custom-class="body_text">We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an <div className="block-component"></div>authorized agent, we may need to collect additional information to verify your identity before processing your request.</span></span></div><div>
<br></br></div><div>
<span>
<span data-custom-class="body_text">Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</span></span></div><div>
<br></br></div><div>
<span>
<u><span data-custom-class="body_text">Right to appeal</span></u></span></div><div>
<span>
<br></br></span></div><div>
<span>
<span data-custom-class="body_text">If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it.&nbsp;</span><span data-custom-class="body_text">If you wish to appeal our decision, please email us at <div className="block-component"></div>contact@daydream.company<div className="else-block"></div>. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the&nbsp;</span></span><a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint" rel="noopener noreferrer" target="_blank">
<span>
<span data-custom-class="link">Attorney General to submit a complaint</span></span></a><span>
<span data-custom-class="body_text">.</span></span></div><div>
<br></br></div><div id="policyupdates">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">13. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated <div className="block-component"></div>"Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span></span></div><div>
<br></br></div><div id="contact">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">If you have questions or comments about this notice, you may <span>
<span data-custom-class="body_text"><div className="block-component"></div>email us at contact@daydream.company</span></span><span>
<span>
<span data-custom-class="body_text">&nbsp;or by post to:</span></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span>
<span data-custom-class="body_text">Daydream Starter Inc.</span></span></span></span></span><span data-custom-class="body_text">
<span>
<span data-custom-class="body_text"><div className="block-component"></div></span></span></span></span></span></div><div>
<span>
<span data-custom-class="body_text">3930 Hillcrest Road Wayzata, MN 55391<span>
</span></span></span></div><div>
</div><div>
<span data-custom-class="body_text">
United States<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text">
<span>
<span>
<div className="statement-end-if-in-editor"></div></span></span></span></span></span></span></span></span></span></span><div className="block-component">
<span>
<span data-custom-class="body_text"></span></span></div><div className="statement-end-if-in-editor"></div><span data-custom-class="body_text">
<span>
<span>
<div className="statement-end-if-in-editor"></div></span></span></span><span data-custom-class="body_text">
<span>
<span data-custom-class="body_text">
<span>
<div className="statement-end-if-in-editor">
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"><div className="block-component"></div></div></span></span></span></div></span></span></span><span>
<span data-custom-class="body_text">
<span>
<span>
<span data-custom-class="body_text"><div className="statement-end-if-in-editor"><div className="block-component"></div></div></span></span></span></span></span></span></div><div>
<br></br></div><div id="request">
<span>
<span>
<span>
<span>
<span id="control">
<strong><span data-custom-class="heading_1">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong></span></span></span></span></span></div><div>
<br></br></div><div>
<span>
<span>
<span data-custom-class="body_text"><div className="block-component"></div>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. <div className="else-block"></div>To request to review, update, or delete your personal information, please <div className="block-component"></div></span><span data-custom-class="body_text">visit: contact@daydream.company<div className="else-block"></div></span></span>
	  </span>
	  </div>
      </div>
      </div>
	  </div>
	  </div>
	  </div>
	  </div>
	  </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

        {/* <div className="space-70" /> */}
      </Container>
    </div>

    </div>
    </>
  );
}
