/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCarousel,
  Button,
} from "reactstrap";

const carouselItems = [
  {
    src: require("assets/img/Workforce.png").default,
    altText: "Slide 1",
    caption: "",
  },
 ];
 
/*
const carouselItems = [
 {
    src: require("assets/img/Dashboard-CreateWorkforce.png").default,
    altText: "Slide 1",
    caption: "",
  },
  {
    src: require("assets/img/Substitute_In_Future.png").default,
    altText: "Slide 2",
    caption: "",
  },
  {
    src: require("assets/img/Substitute_SubDetailsYellow.png").default,
    altText: "Slide 3",
    caption: "",
  },
  {
    src: require("assets/img/Substitute_CreateWorkforce_Contacts.png").default,
    altText: "Slide 4",
    caption: "",
  },
];
*/

const carouselWurusItems = [
  /*{
    src: require("assets/img/WurusShopFloor-NewColorPalette.png").default,
    altText: "Slide 1",
    caption: "",
  },*/
  {
    src: require("assets/img/Wurus_Dark.png").default,
    altText: "Slide 1",
    caption: "",
  },
];


const carouselMainstreetItems = [
  {
    src: require("assets/img/Mainstreet.png").default,
    altText: "Slide 1",
    caption: "",
  },
 ];
 

const carouselLessItems = [
  {
    src: require("assets/img/Less.png").default,
  },
 ];

// const rowStyle = { width: '50%', marginLeft: 'auto', marginRight: 'auto' };

const rowStyle = { width: '80%', marginLeft: 'auto', marginRight: 'auto' };
const rowStyle1 = { width: '100%', marginLeft: 'auto', marginRight: 'auto' };

const fontSizeStyle = { fontSize: 'larger' };

const cardStyle = { background: 'rebeccapurple' };
const h1Style = { color: 'rebeccapurple' };
const h5Style = { color: 'rebeccapurple' , 
position: 'relative',
    zIndex: 1,
    fontSize: '9.5em',
    marginLeft: '-4px',
    fontWeight: 900, };

export default function Tabs() {
  const [iconTabs, setIconsTabs] = React.useState(1);
  const [textTabs, setTextTabs] = React.useState(4);
  return (
    <div className="section">
      {/*
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row style={rowStyle} className="justify-content-between align-items-center1">
              <UncontrolledCarousel items={carouselItems} />
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">Explore Substitute</h1>
            <h5 className="text-on-back">01</h5>
            <p className="profile-description text-left">
              What if you could always find a replacement worker
              When you need it without making any effort?
            </p>
          </Col>
        </Row>
      </Container>
      <div className="space-70" />
      */}
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row style={rowStyle} className="justify-content-between align-items-center1">
              <UncontrolledCarousel items={carouselItems} />
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">Explore Substitute</h1>
            <h5 className="text-on-back">01</h5>
            <p style={fontSizeStyle} className="profile-description text-left">
              What if you could always find a replacement worker
              When you need it without making any effort?
            </p>
          </Col>
        </Row>
      </Container>
      <div className="space-70" />
      
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <h1 className="profile-title text-left">Explore Wurus</h1>
            <h5 className="text-on-back">02</h5>
            <p style={fontSizeStyle} className=" text-left">
            What if anyone could have a personal shopper and have the same access to all goods regardless of one lives?
            </p>
          </Col>
          <Col md="5">
            <Row className="justify-content-between align-items-center1">
              <UncontrolledCarousel items={carouselWurusItems} />
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="space-70" />


      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <Row style={rowStyle} className="justify-content-between align-items-center1">
              <UncontrolledCarousel items={carouselMainstreetItems} />
            </Row>
          </Col>
          <Col md="5">
            <h1 className="profile-title text-left">Explore Mainstreet</h1>
            <h5 className="text-on-back">03</h5>
            <p style={fontSizeStyle} className="profile-description text-left">
              What if we could all have similar access and insights
              To the investment community?
            </p>
          </Col>
        </Row>
      </Container>
      <div className="space-70" />
      
      <Container>
        <Row className="justify-content-between">
          <Col md="6">
            <h1 className="profile-title text-left">Explore Less</h1>
            <h5 className="text-on-back">04</h5>
            <p style={fontSizeStyle} className="profile-description text-left">
              What if you could travel from one country to and other without ever needing to drag your luggage with you to your destination and back ?
            </p>
          </Col>
          <Col md="5">
            <Row className="justify-content-between align-items-center1">
              <UncontrolledCarousel items={carouselLessItems} />
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="space-70" />

      {/*
      <Container>
          <Row className="justify-content-between">
            <Col md="6">
            <h1 style={h1Style} className="profile-title text-left">Explore Mainstreet</h1>
              <h5 style={h5Style} >03</h5>
              <Card style={cardStyle}>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + iconTabs}>
                  <TabPane tabId="link1">
                    <p>
                    What if we could all have similar access and insights
                    To the investment community?<br /><br />
                    COMING SOON.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            </Col>
            <Col md="5">
              <h1 style={h1Style} className="profile-title text-left">Explore L’ess</h1>
              <h5 style={h5Style} >04</h5>
              <Card style={cardStyle}>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + iconTabs}>
                  <TabPane tabId="link1">
                    <p>
                    What if you could travel from one country to and other
                    Without ever needing drag your luggage with you to your destination and back ?<br /><br />
                    COMING SOON.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            </Col>
          </Row>
        </Container>
      */}
      </div>
  );
}
