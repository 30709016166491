/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  CustomInput,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert,
  NavbarBrand,
  TabContent,
  TabPane,
  CardBody,
} from "reactstrap";

const styleDiv = { borderRadius:'5px', padding: '10px', background: '#3358f4', marginLeft: 'auto', marginRight: 'auto' };

export default function Basics() {
  const [inputFocus, setInputFocus] = React.useState(false);
  
  return (
    <div className="section section-basic" id="basic-elements">
      <img
        alt="..."
        className="path"
        src={require("assets/img/path1.png").default}
      />
      <Container>
        <h2 className="title">What is Daydream ?</h2>
        <div style={styleDiv}>
          <h3>Daydream is a diverse group of inventors, and entrepreneurs who build and launch technologies that aim to improve the lives of millions, even billions, of people. Our goal is make the World a better place for all by always focusing on improving the life of the humans involved. We focus on projects that have a transformative aspiration to change how we live, including the rules of the game. We approach problems with the ambition and speed of a startup and the rigor of laboratory.</h3>
        </div>
        
        {/* <div className="space-70" /> */}
      </Container>
    </div>
  );
}
